import { useState, useEffect } from 'react'
import Login from './Components/Login.js'
import Navbar2 from './Components/Navbar2.js'
import Param from './Components/Param'
import Home from './Components/Home'
import Logout from './Components/Logout'
import Histo from './Components/Histo'
import Demandes from './Components/Demandes'
import PoleEmploi from './Components/PoleEmploi'
import Iban2 from './Components/Iban2'
import Help from './Components/Help'
import './App.css'
import { Routes, Route } from 'react-router-dom'
import axios from 'axios';
import {
  useNavigate,
  useLocation,
} from "react-router-dom";


function App() {

  var localToken = localStorage.getItem('Token')  // Lecture du token déjà présent en local storage

  let navigate = useNavigate();

  const [state, setState] = useState({
    token: '',
    isLogged: false,
    selected: { path: '/', title: 'Accueil' },
    poleEmploiData: null,
    hasFetchedPoleEmploiData: false,
    hasPendingIBANChange: false,
    hideNavBar: false,
  })

  const setNavBar = (s) => {
    setState({ ...state, selected: s })
  }


  const unit = ['€', '%']
  const location = useLocation()

  useEffect(() => {
    if (location.pathname == "/" && location.state && location.state.inputMode) {
      setState({ ...state, selected: { ...state.selected, title: 'Demander un retrait' } })
    }
    else if (location.pathname === "/Login") {
      setState({ ...state, hideNavBar: true });
    }
    else if (state.hideNavBar) {
      setState({ ...state, hideNavBar: false });
    }
  }, [location])


  const getToken = (data) => {
    console.log("getToken");
    let cet = {
      isOpen: data.user.status === 'Ouvert' ? true : false,
      status: data.user.status, // Ouvert || Fermé || Non ouvert
      dopen: data.user.dopen !== undefined ? new Date(data.user.dopen.match(/\b(\d{2})\/(\d{2})\/(\d{4})\b/)[3] + '-' +
        data.user.dopen.match(/\b(\d{2})\/(\d{2})\/(\d{4})\b/)[2] + '-' +
        data.user.dopen.match(/\b(\d{2})\/(\d{2})\/(\d{4})\b/)[1]
      ) : null,      // dend:data.user.dend !== undefined ? new Date( data.user.dend.match(/\b(\d{2})\/(\d{2})\/(\d{4})\b/)[3]+'-'+
      dend: data.user.dend ? new Date(data.user.dend.match(/\b(\d{2})\/(\d{2})\/(\d{4})\b/)[3] + '-' +
        data.user.dend.match(/\b(\d{2})\/(\d{2})\/(\d{4})\b/)[2] + '-' +
        data.user.dend.match(/\b(\d{2})\/(\d{2})\/(\d{4})\b/)[1]
      ) : null,      // Les unités € ou % 
      ifmUnit: '%',//unit.indexOf(data.user.ifmRate.substr(-1))!==-1?data.user.ifmRate.substr(-1):'',
      iccpUnit: '%',//unit.indexOf(data.user.iccpRate.substr(-1))!==-1?data.user.iccpRate.substr(-1):'',
      bonusUnit: '%',//unit.indexOf(data.user.bonusRate.substr(-1))!==-1?data.user.bonusRate.substr(-1):'',
      // Les valeurs
      taux_ifm: parseInt(data.user.taux_ifm), //(data.user.ifmRate.match(/\b(\d{0,3})\b/) !== null)?parseInt(data.user.ifmRate.match(/\b(\d{0,3})\b/)[0]):0,
      taux_iccp: parseInt(data.user.taux_iccp), //(data.user.iccpRate.match(/\b(\d{0,3})\b/) !== null)?parseInt(data.user.iccpRate.match(/\b(\d{0,3})\b/)[0]):0,
      taux_primes: parseInt(data.user.taux_primes), //(data.user.bonusRate.match(/\b(\d{0,3})\b/) !== null)?parseInt(data.user.bonusRate.match(/\b(\d{0,3})\b/)[0]):0,
      // Les switch
      option_ifm: data.user.option_ifm,
      option_iccp: data.user.option_iccp,
      option_primes: data.user.option_primes
    }

    if (data.user.solde) {
      data.user.solde.forEach(elt => {
        elt.montant = 0           // Saisie du montant
        elt.totalité = false      // Passe à vrai quand la totalité est demandée
      });
    }
    setState({
      ...state, token: data.token, isLogged: true,
      name: data.user.name,
      firstname: data.user.firstname,
      matricule: data.user.matricule,
      agence: data.user.agence,       // Agence de ratachement 
      mail: data.user.mail,
      tel: data.user.tel,
      iban: data.user.iban,         // Iban enregistré, pour vérification
      isOpen: data.user.status === 'Ouvert' ? true : false,
      status: data.user.status, // Ouvert || Fermé || Non ouvert
      cet: cet,
      solde: data.user.solde.map(el => { return { ...el, Demande: el.Demande ? el.Demande : 0, Solde: el.Solde } }), // Ajout de "demande" : le solde des demandes de versements non traitées
      histo: data.user.histo,
      update: data.user.update,
      help: false,
      showLogoutPopup: false,
    })
    setTimeout(() => {
      logOut();
    }, 1800000);
  }

  const api = '';

  // Récupétation du token d'entrée sur le site ===========================================
  var queryParameters = new URLSearchParams(window.location.search)
  var t = queryParameters.get("t")

  useEffect(() => {
    hello(t);
    if (location.pathname !== '/Logout') {
      // navigate('/');
    }
  }, [])

  const logOut = (message) => {
    localStorage.removeItem('Token');
    localStorage.clear();
    setState({ ...state, token: '', isLogged: false });
    navigate("/Login", { state: { logoutMessage: message },  });
    location.reload();
  }



  // Prise en compte de la demande de versement sur la totalité
  const setTotalité = (agence) => {
    let solde = state.solde
    let état = solde.find(w => w.RGPCNT_ID == agence).totalité
    solde.find(w => w.RGPCNT_ID == agence).totalité = !état
    setState({ ...state, solde: solde })
    return !état
  }


  // Modification du tableau des soldes pour prendre en compte le montant des demandes non encore traitées  
  const takeDem = (sumDem) => {
    console.log("sumDem", sumDem)
    let solde = state.solde.map(el => {
      let demande = sumDem.find(i => i.age === el.RGPCNT_ID.toString())
      console.log(el.RGPCNT_ID, demande)
      return { ...el, demande: demande ? demande.montant : 0 }
    })

    console.log("Nouveau tableau des soldes", solde)
  }


  const hello = (t) => {
    // Récupérer les datas normalement reçues lors de l'authentificaiton, du fait que l'on présente un bon token
    if (t) {
      axios.post(api + "/api/authv", { message: 'salut ' }, { headers: { token2: t } })
        .catch((error) => {
          if (error.response.status == 423) {
            logOut(error.response.data.message);
          }
        })
        .then((res, err) => {
          if (err) { console.log('salut err', err) }
          if (res) {
            getToken(res.data)
          }
        })
    } else if (localToken) {
      axios.post(api + "/api/authv", { message: 'salut' }, { headers: { token: localToken } })
        .catch((error) => {
          if (error.response && error.response.status == 423) {
            logOut(error.response.data.message);
          }
        })
        .then((res, err) => {
          if (err) { console.log('salut err', err) }
          if (res) {
            getToken(res.data)
          }
        })
    }
  }

  if (!state.hasFetchedPoleEmploiData && !!state.token) {
    axios.post(api + "api/n3", { proc: "display_declare" }, { headers: { token: state.token } })
      .catch((error) => {
        if (error.response.status == 423) {
          logOut(error.response.data.message);
        }
      })
      .then(({ data, ...result }) => {
        axios.post(api + "api/n3", { proc: "display_IBAN" }, { headers: { token: state.token } }).then((dataIBAN) => {
          setState({ ...state, hasPendingIBANChange: dataIBAN.data.iban[0]["IBAN_UPDATE"] === 1, poleEmploiData: data.declare, hasFetchedPoleEmploiData: true });
        });
      });
  }

  const refreshIBANStatus = () => {
    axios.post(api + "api/n3", { proc: "display_IBAN" }, { headers: { token: state.token } })
      .catch((error) => {
        if (error.response.status == 423) {
          logOut(error.response.data.message);
        }
      })
      .then((dataIBAN) => {
        setState({ ...state, hasPendingIBANChange: dataIBAN.data.iban[0]["IBAN_UPDATE"] === 1 });
      });
  }


  const toggleLogoutPopup = () => {
    setState({ ...state, showLogoutPopup: !state.showLogoutPopup });
  }

  return (
    <div className="App">

      {
        state.showLogoutPopup &&
        <Logout logOut={logOut} onCancel={toggleLogoutPopup} />
      }

      {
        state.isLogged &&
        <>
          {
            !state.hideNavBar &&
            <Navbar2 selected={state.selected.path} title={state.selected.title} onLogoutClicked={toggleLogoutPopup} />
          }
        </>
      }
      <Routes>
        {
          state.isLogged &&
          <>
            <Route path='/Demandes' element={<Demandes api={api} p={state} logOut={logOut} />}></Route>
            <Route path='/Histo' element={<Histo api={api} p={state} logOut={logOut} />}></Route>
            <Route path='/Iban2' element={<Iban2 api={api} p={state} logOut={logOut} refreshIBANStatus={refreshIBANStatus} />}></Route>
            <Route path="/PoleEmploi" element={<PoleEmploi lines={state.poleEmploiData} />}></Route>
            <Route path='/Param' element={<Param p={state} api={api} logOut={logOut} />}></Route>
            <Route path='/Login' element={<Login api={api} getToken={getToken} />}></Route>
            <Route path="/Aide" element={<Help />}></Route>
            <Route path='/' element={<Home
              api={api}
              takeDem={takeDem}
              solde={state.solde}
              name={{ nom: state.name, pre: state.firstname }}
              poleEmploiData={state.poleEmploiData}
              hasPendingIBANChange={state.hasPendingIBANChange}
              matricule={state.matricule}
              iban={state.iban}
              token={state.token}
              baseUrl={state.baseUrl}
              setTotalité={setTotalité}
              setNavBar={setNavBar}
              logOut={logOut}
              update={state.update}
               />}>

            </Route>
          </>
        }
        {
          !state.isLogged &&
          <Route path='*' element={<Login api={api} getToken={getToken} />}></Route>
        }


      </Routes>
    </div>
  )
}

export default App;