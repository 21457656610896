import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

export default styled(Button)(({ theme }) => ({
    padding: "0.875rem 2rem",
    alignItems: 'center',
    height: '3rem',
    display: 'inline-flex',
    backgroundColor: '#E11C31',
    color: 'white',
    borderRadius: '1.5rem',
    borderStyle: 'none',
    fontFamily: 'Manrope',
    fontWeight: 700,
    fontSize: '0.875rem',
    textTransform: 'none',

    [`&:disabled`]: {
      backgroundColor: '#808080',
      color: 'white',
    },

    ['&.MuiButton-outlined']: {
      backgroundColor: 'white',
      color: '#E11C31',
      border: '1px solid #E11C31',
      
      [`&:disabled`]: {
        border: '1px solid #808080',
        color: '#808080',
      },
    }

  }));