import TextField from '@mui/material/TextField';
import { useState } from 'react'
import axios from 'axios';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import StyledButton from './StyledButton';
import BlueAlert from './BlueAlert';
import InfoIcon from '@mui/icons-material/Info';
import { AlertTitle, Divider } from '@mui/material';

import Popup from 'reactjs-popup';
import {
  useNavigate,
  useLocation,
} from "react-router-dom";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


export default function Iban2(p) {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const [state, setState] = useState({
    isFerified: false,
    compleeted: false,
    ibanError: true,        // par défaut  : la saisie est fausse
    iban: '',
    bic: '',
    dom: '',
    preBene: '',
    nomBene: '',
    ibanOk: false,
    bicOk: false,
    domOk: false,
    nomOk: false,
    preOk: false
  })


  const handleIbanChange = (e) => {
    /*
    Validation n'intervient que lorsque l'IBAN et correct + nom prénom correspondent 
    + BIC  edomiciliation présents
    */

    let ibanOk = false, nomOk = false, preOk = false, bicOk = false, domOk = false

    if (e.target.name === 'iban') {
      ibanOk = isValidIBANNumber(e.target.value)
    } else {
      ibanOk = isValidIBANNumber(state.iban)
    }

    if (e.target.name === 'preBene') {
      if (e.target.value.toUpperCase() == p.p.firstname.toUpperCase()) { preOk = true }
    } else {
      if (state.preBene == p.p.firstname) { preOk = true }
    }

    if (e.target.name === 'nomBene') {
      if (e.target.value.toUpperCase() == p.p.name.toUpperCase()) {
        nomOk = true;
      }
    } else {
      if (state.nomBene == p.p.name) { nomOk = true }
    }

    if (e.target.name === 'bic') {
      if (e.target.value.length > 2) { bicOk = true }
    } else {
      if (state.bic.length > 2) { bicOk = true }
    }

    if (e.target.name === 'dom') {
      if (e.target.value.length > 3) { domOk = true }
    } else {
      if (state.dom.length > 3) { domOk = true }
    }

    let KO = true // c'est en erreur 
    if (!nomOk || !preOk || !ibanOk || !domOk || !bicOk) { KO = true } else { KO = false }

    setState({ ...state, [e.target.name]: e.target.value.toUpperCase(), ibanError: KO, ibanOk: ibanOk, bicOk: bicOk, domOk: domOk, nomOk: nomOk, preOk: preOk })
  }

  const validIban = () => {
    setOpen(true);
    // Envoi d'une demande d'IBAN
    const dem = {
      type: 'iban',
      agence: p.agence,
      iban: state.iban,
      bic: state.bic,
      dom: state.dom,
      preBene: state.preBene,
      nomBene: state.nomBene
    }

    axios.post(p.api + "/api/demande", dem, { headers: { token: p.p.token } })
    .catch((error) => {
      if (error.response.status == 423) {
        p.logOut(error.response.data.message);
      }
    })
    .then(() => {
      p.refreshIBANStatus();
    });

  }

  //*********************************************************************************************************************** */
  /*
  * Returns 1 if the IBAN is valid 
  * Returns FALSE if the IBAN's length is not as should be (for CY the IBAN Should be 28 chars long starting with CY )
  * Returns any other number (checksum) when the IBAN is invalid (check digits do not match)
  */
  function isValidIBANNumber(input) {
    var CODE_LENGTHS = {
      AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29,
      CH: 21, CR: 21, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24,
      FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21,
      HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28,
      LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27,
      MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
      RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26,
      AL: 28, BY: 28, CR: 22, EG: 29, GE: 22, IQ: 23, LC: 32, SC: 31, ST: 25,
      SV: 28, TL: 23, UA: 29, VA: 22, VG: 24, XK: 20
    };
    var iban = String(input).toUpperCase().replace(/[^A-Z0-9]/g, ''), // keep only alphanumeric characters
      code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), // match and capture (1) the country code, (2) the check digits, and (3) the rest
      digits;
    // check syntax and length
    if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
      return false;
    }
    // rearrange country code and check digits, and convert chars to ints
    digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
      return letter.charCodeAt(0) - 55;
    });
    // final check
    return mod97(digits);
  }

  function mod97(string) {
    var checksum = string.slice(0, 2), fragment;
    for (var offset = 2; offset < string.length; offset += 7) {
      fragment = String(checksum) + string.substring(offset, offset + 7);
      checksum = parseInt(fragment, 10) % 97;
    }
    return checksum;
  }
  //*****************************************************


  return (
    <>
      <div className='Infos'>
        <Typography sx={{ fontFamily: 'Open Sans', color: '#161616', fontSize: '0.875rem', fontWeight: 400, lineHeight: '1.25rem', textAlign: 'center', width: '100%', marginBottom: '1rem' }}>
          IBAN actuellement renseigné : {p.p.iban}
        </Typography>
        {
          p.p.hasPendingIBANChange &&

          <BlueAlert severity="info" sx={{ marginLeft: '1rem', marginRight: '1rem', }} iconMapping={{ info: <InfoIcon sx={{ color: '#1D91C3' }} /> }}>
              <AlertTitle>
                Modification de coordonnées bancaires
              </AlertTitle>
              Une modification de coordonnées bancaires
              est déjà en cours de validation
            </BlueAlert>        
        }
        {
          !p.p.hasPendingIBANChange &&
          <>
            <TextField
              sx={{ width: '90%', }}
              error={(!!state.iban && state.ibanOk === false)}
              name='iban'
              id="iban2"
              label="Nouvel IBAN"
              value={state.iban}
              onChange={handleIbanChange}
              helperText="Obligatoire"
              margin="dense"
            />

            <TextField
              error={(!!state.bic && state.bicOk === false)}
              sx={{ width: '90%', }}
              name='bic'
              id="iban2"
              label="Code BIC"
              value={state.bic}
              onChange={handleIbanChange}
              helperText="Obligatoire"
              margin="dense"
            />

            <TextField
              error={(!!state.dom && state.domOk === false)}
              sx={{ width: '90%', }}
              name='dom'
              id="iban2"
              label="Domiciliation"
              value={state.dom}
              onChange={handleIbanChange}
              helperText="Obligatoire"
              margin="dense"
            />

            <TextField
              error={(!!state.nomBene && state.nomOk === false)}
              sx={{ width: '90%', }}
              name='nomBene'
              label="Nom du bénéficiaire"
              value={state.nomBene}
              onChange={handleIbanChange}
              helperText="Obligatoire"
              margin="dense"
            />

            <TextField
              error={(!!state.preBene && state.preOk === false)}
              sx={{ width: '90%', }}
              name='preBene'
              label="Prénom du bénéficiaire"
              value={state.preBene}
              onChange={handleIbanChange}
              helperText="Obligatoire"
              margin="dense"
            />

            <Typography sx={{ fontFamily: 'Open Sans', color: '#676767', fontSize: '0.875rem', fontWeight: 600, lineHeight: '1.25rem', textAlign: 'center', width: '100%', marginBottom: '1rem', marginTop: '1rem' }}>
              Ce changement modifie également le versement des salaires
            </Typography>

            <div style={{ margin: '10px', display: 'flex', justifyContent: 'space-between', flexDirection: "column" }}>
              {/* {
            process.env.NODE_ENV === 'development' &&
            <Button onClick={() => setOpen(true)}
            >
              Info
            </Button>
          } */}



              <StyledButton
                onClick={validIban}
                disabled={state.ibanError}
                variant="contained"
                sx={{}}
              >
                Valider
              </StyledButton>
            </div>

          </>
        }


        <Popup open={open}
          // https://react-popup.elazizi.com/controlled-popup
          position="center"
          closeOnDocumentClick={true}
        >
          <div
            style={{ textAlign: 'center' }}
          >
            <p>Votre demande de <strong>modification de coordonnées bancaires</strong> a été enregistrée.</p>
            <p>Un e-mail vous a été envoyé pour confirmer votre demande.</p>
          </div>


          <StyledButton
            onClick={e => { navigate("/") }}
            sx={{ marginBottom: '1rem', marginTop: '1rem' }}
            variant="contained">
            OK
          </StyledButton>

        </Popup>

      </div>
    </>

  )
}