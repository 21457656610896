import { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  useParams,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { SvgIcon } from '@mui/material';
import { ReactComponent as HomeAltIcon } from '../icons/home-alt.svg';
import { ReactComponent as MoneyCoinsIcon } from '../icons/money-coins.svg';
import { ReactComponent as CogIcon } from '../icons/cog.svg';
import { ReactComponent as SortAltIcon } from '../icons/sort-alt.svg';
import { ReactComponent as CreditCardIcon } from '../icons/credit-card-front.svg';
import { ReactComponent as PowerOffIcon } from '../icons/power-off.svg';
import { ReactComponent as SyncAltIcon } from '../icons/sync-alt.svg';

const pages = [{ name: 'Accueil', link: '/', icon: () => <SvgIcon sx={{ fontSize: '1.125rem', paddingRight: '0.5rem' }} component={HomeAltIcon} inheritViewBox /> },
{ name: 'Mes demandes', link: '/Demandes', icon: () => <SvgIcon sx={{ fontSize: '1.125rem', paddingRight: '0.5rem' }} component={MoneyCoinsIcon} inheritViewBox /> },
{ name: 'Relevé des opérations', link: '/Histo', icon: () => <SvgIcon sx={{ fontSize: '1.125rem', paddingRight: '0.5rem' }} component={SortAltIcon} inheritViewBox /> },
{ name: 'Actualisation Pôle Emploi', link: '/PoleEmploi', icon: () => <SvgIcon sx={{ fontSize: '1.125rem', paddingRight: '0.5rem' }} component={SyncAltIcon} inheritViewBox /> },
{ name: 'Paramètres d\'épargne', link: '/Param', icon: () => <SvgIcon sx={{ fontSize: '1.125rem', paddingRight: '0.5rem' }} component={CogIcon} inheritViewBox /> },
{ name: 'Modifier mon IBAN', link: '/Iban2', icon: () => <SvgIcon sx={{ fontSize: '1.125rem', paddingRight: '0.5rem' }} component={CreditCardIcon} inheritViewBox /> }, // La page IBAN 
{ name: 'Aide', link: '/Aide', icon: () => <SvgIcon sx={{ fontSize: '1.125rem', paddingRight: '0.5rem' }} component={HelpOutlineIcon} inheritViewBox /> }, // La page IBAN 

  // { name: 'Déconnexion', link: '/Logout', icon: () => <SvgIcon sx={{ fontSize: '1.125rem', paddingRight: '0.5rem' }} component={PowerOffIcon} inheritViewBox /> }
];

const Navbar2 = ({ selected, title, onLogoutClicked }) => {




  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [selectedPath, setSelectedPath] = useState('/');    // L'élément du menu sélectionné 
  const [titleState, setTitle] = useState('Accueil');    // L'élément du menu sélectionné 

  const location = useLocation()

  useEffect(() => {
    setSelectedPath(selected);
    setTitle(title);
  }, [selected, title])

  useEffect(() => {
    const page = pages.find(p => p.link === location.pathname);
    if (!page) {
      return;
    }
    setSelectedPath(page.link);
    setTitle(page.name);
  }, [location])



  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    // Menu utilisateur : supprimé
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (page, name) => {
    if (page) {
      navigate(page, { state: { inputMode: false } })
      setSelectedPath(page);
      setTitle(name);
    }
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  if (selected === 'test') {
    handleCloseNavMenu('/', 'Accueil')
  }

  return (
    <AppBar
      position="sticky"
      elevation={0}
      style={{
        borderRadius: "0rem 0rem 1rem 0rem", background: "#E11C31"
      }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }} style={{
            zIndex: '1',
          }}>

            <IconButton
              size="large"
              aria-label="navigation menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorReference='anchorPosition'
              //anchorEl={anchorElNav}
              anchorPosition={{ top: 70, left: 1 }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={() => handleCloseNavMenu(null, null)}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.name} onClick={() => handleCloseNavMenu(page.link, page.name)}>

                  <div className='menu-active-item-marker' style={{ visibility: page.link === selectedPath ? "visible" : "hidden" }}></div>
                  {page.icon && page.icon()}
                  <Typography textAlign="center">{page.name}</Typography>
                </MenuItem>
              ))}
              <MenuItem key={"deconnexion"} onClick={() => { onLogoutClicked(); setAnchorElNav(null); }}
              >
                <div className='menu-active-item-marker' style={{ visibility: "hidden" }}></div>
                <SvgIcon sx={{ fontSize: '1.125rem', paddingRight: '0.5rem' }} component={PowerOffIcon} inheritViewBox />
                <Typography textAlign="center">Déconnexion</Typography>
              </MenuItem>

              {/* <MenuItem key="Aide">
                <div className='menu-active-item-marker' style={{ visibility: "hidden" }}></div>
                <a className='help-link' onClick={() => navigate("/Aide")}  title="Accéder à l'aide">Accéder à l'aide</a>
              </MenuItem> */}
            </Menu>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {
              pages.map((page) => (
                <Button
                  key={page.name}
                  onClick={() => handleCloseNavMenu(page.link, page.name)}
                  sx={{ my: 2, color: 'white', display: 'block', borderBottom: 'solid', borderRadius: 0, borderWidth: (page.link === location.pathname) ? '7px' : 0, fontFamily: 'Manrope' }}
                >
                  {page.name}
                </Button>
              ))}
            <Button
              key={'logout'}
              onClick={() => { onLogoutClicked(); setAnchorElNav(null); }}

              sx={{ my: 2, color: 'white', display: 'block', fontFamily: 'Manrope' }}
            >
              Déconnexion
            </Button>
          </Box>

          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, fontFamily: 'Manrope', fontSize: '1.125rem', fontWeight: 700, lineHeight: '1.625rem' }}
          >
            {titleState}
          </Typography>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Navbar2;
