import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import StyledButton from './StyledButton';


export default function PendingIBANChangeError({onClose}) {
    return (
        <Popup open={true}
            position="center"
            closeOnDocumentClick={true}
            onClose={e =>  onClose() }
        >
            <div
                style={{ textAlign: 'center', marginBottom: '10px' }}
            >
                Modification de coordonnées bancaires en cours de validation.
            </div>

            <StyledButton
                onClick={e => onClose() }
                sx={{ marginBottom: '1rem', marginTop: '1rem' }}
                variant="contained">
                OK
            </StyledButton>

        </Popup>
    )
}