import { useRef, useState, useEffect } from 'react'
// import Input from '@mui/material/Input';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import { CheckCircle, PropaneSharp } from '@mui/icons-material';
import { AlertTitle, Typography, autocompleteClasses } from '@mui/material';
import StyledButton from './StyledButton';
import GreenAlert from './GreenAlert';
import BlueAlert from './BlueAlert';
import InfoIcon from '@mui/icons-material/Info';
import {
    useNavigate,
    useLocation,
} from "react-router-dom";

// import {MuiThemeProvider} from "@Mui/material/styles/ThemeProvider.js"

/*
Plusieurs méthodes de connexion : 
    1   le nom + mail ou tel permettent l'envoi d'une vérification + token OK
    2   login (mail) + mot de passe 
    3   oAuth2 avec un compte google ou Apple
*/


export default function Login({ getToken, api }) {
    //let api = process.env.REACT_APP_CETB_URL
    console.log("api transmis à Login", api)

    // let navigate = useNavigate();
    const [log, setLog] = useState({
        name: '', //'BRUNO',//'BRUNO', // LIEURY
        surname: '',
        mail: '', //'brunolouis00@gmail.com',//'brunolouis00@gmail.com', // cherchesInfos
        // tel:'063215',
        token1: '',
        token2: '',
        verif1: '', // code à transmettre 
        code: '',    // code reçu en clair pour les tests
        envoi: true,
        method: 1,
        emailError: '',
        codeError: '',
        allowResendCode: false,
        errorMessage: ''
    })

    const location = useLocation();
    const navigate = useNavigate();

    const handelChange = (e) => {
        let value = e.target.value
        if (e.target.name == 'name') { value = value.toUpperCase() }
        if (e.target.name == 'code' && value.length >= 4) { log.envoi = false }
        setLog({ ...log, [e.target.name]: value, emailError: '', codeError: '' })

    }

    useEffect(() => {
        setLog({ ...log, errorMessage: location.state ? location.state.logoutMessage : null });
    }, [location])

    // ETAPE 1 : Envoi des éléments de connexion sur /auth
    const sendLog = () => {
        axios.post(api + '/api/auth', { name: log.name, tel: log.tel, mail: log.mail })
            .catch((error) => {
                console.log(error);
                //API will respond with status 406 if email is unknown
                if (error.response) {
                    if (error.response.status === 406) {
                        setLog({ ...log, emailError: 'Adresse e-mail incorrecte', allowResendCode: false, errorMessage: null });
                    }
                    else if (error.response.status == 423) {
                        setLog({ ...log, errorMessage: error.response.data.message });
                    }
                }

            })
            .then(function (res) {
                if (res.data.token !== undefined) {
                    setLog({ ...log, token1: res.data.token, code: res.data.code, envoi: false, allowResendCode: false, errorMessage: null });
                } else (
                    console.log("sendLog pas de token", res.data)
                )
            })

    }

    // ETAPE 2 : Envoi du code de vérification, et si ok : réception d'un token
    const envoiCode = () => {
        axios.post(api + '/api/authv', { code: log.code }, { headers: { token: log.token1 } })
            .catch((error) => {
                if (error.response.status == 423) {
                    setLog({ ...log, errorMessage: error.response.data.message });
                }
                //API will respond with status 400 if email is unknown
                else if (error.response.status === 400) {
                    setLog({ ...log, codeError: 'Code de vérification erroné', errorMessage: null })
                }
                else if (error.response.status === 500) {
                    setLog({ ...log, codeError: 'Code de vérification expiré', allowResendCode: true, errorMessage: null })
                }
            })
            .then((res, err) => {
                if (res.data.token) {
                    console.log('reception du token')
                    localStorage.setItem("Token", res.data.token)
                    getToken(res.data);
                    navigate("/");
                    setLog({ ...log, errorMessage: null })
                }
                if (err) {
                    console.log(err)
                }
            })
    }

    return (
        <div >

            <div
                style={{
                    display: 'Flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    height: '100vh',
                    backgroundImage: "url('./actual-big.svg')",
                    backgroundRepeat: 'no-repeat',
                    backgroundPositionY: 'bottom',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifCcontent: 'center',
                    }}
                >

                    <div className='logoLogin'>
                        <img src='./leader-login.png'></img>
                    </div>

                    <Typography variant="h1" sx={{
                        textAlign: 'center',
                        fontSize: '1.125rem',
                        fontWeight: 700,
                        lineHeight: '1.625rem',
                        color: '#161616',
                        fontFamily: 'Manrope',
                        margin: '2.5rem 1.5rem'
                    }}>
                        Me connecter à mon Livret Leader
                    </Typography>

                    <TextField
                        sx={{ marginBottom: '1rem' }}
                        required
                        name='name'
                        id="nom"
                        label="Nom"
                        // defaultValue={log.name}
                        value={log.name}
                        helperText="Obligatoire"
                        onChange={handelChange}
                    />

                    <TextField
                        sx={{ marginBottom: '1rem' }}
                        required
                        id="mail"
                        name='mail'
                        label="E-mail"
                        error={!!log.emailError}
                        helperText={log.emailError ? log.emailError : "Obligatoire"}
                        value={log.mail}
                        // defaultValue={'herveetzazou@hotmail.fr'}
                        onChange={handelChange}
                    />

                    {log.token1.length > 1
                        && log.token2.length < 2 &&
                        <>
                            <GreenAlert severity="success" iconMapping={{ success: <CheckCircle sx={{ color: '#23956D' }} /> }}
                            >
                                <AlertTitle>
                                    Code de vérification envoyé par mail
                                </AlertTitle>
                                Veuillez consulter vos mails
                            </GreenAlert>

                            <TextField
                                required
                                id="verif"
                                name='code'
                                label="Code de vérification"
                                error={!!log.codeError}
                                helperText={log.codeError ? log.codeError : "Obligatoire"}
                                value={log.code}
                                onChange={handelChange}
                            // defaultValue={''}
                            />
                        </>
                    }

                    <p />

                    {log.token1.length < 2
                        && log.token2.length < 2 &&
                        <StyledButton
                            // Ce bouton n'est visible qu'avant la demande de code et réception du token
                            variant="contained"
                            onClick={sendLog}
                            disabled={log.name.length <= 2 && log.mail.length <= 2}
                        >
                            Recevoir un code de vérification
                        </StyledButton>
                    }


                    {log.token1.length > 4 && log.token2.length < 2 &&
                        <>

                            <StyledButton
                                // Boution d'envoi du code = Ne s'affiche que si la saisie du code a eu lieu ( au mois )
                                variant="contained"
                                onClick={(e) => {
                                    envoiCode()
                                }
                                }
                                disabled={log.code.length < 2 || log.envoi}
                            >
                                J'envoie mon code de vérification
                            </StyledButton>
                            <StyledButton
                                variant="outlined"
                                onClick={sendLog}
                                sx={{ marginTop: '1rem' }}
                                disabled={log.name.length <= 2 && log.mail.length <= 2 || !log.allowResendCode}
                            >
                                Renvoyer un code
                            </StyledButton>
                        </>
                    }

                    {log.errorMessage &&
                        <BlueAlert severity="info" sx={{ marginLeft: '1rem', marginRight: '1rem', marginTop: '1rem' }} iconMapping={{ info: <InfoIcon sx={{ color: '#1D91C3' }} /> }}>
                            {log.errorMessage}
                        </BlueAlert>
                    }
                </Box>
            </div>
        </div>
    )
}