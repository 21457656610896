import { useState, useEffect } from 'react'
// import Input from '@mui/material/Input';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import FormControlLabel from '@mui/material/FormControlLabel';
import StyledSwitch from './StyledSwitch';
import BlueAlert from './BlueAlert';
import InfoIcon from '@mui/icons-material/Info';
import { AlertTitle } from '@mui/material';
import StyledButton from './StyledButton';
import Popup from 'reactjs-popup';



// import {MuiThemeProvider} from "@Mui/material/styles/ThemeProvider.js"


/*
Param.js est une versio simplifiée de Infos.js pour présenter et modifier les paramètres du CET

- Les paramètres du CET sont reçus dans les props.
- Une copie du cet est mise en state local
- en cas de demande de modification, c'est le state modifié qui est transmis

Divers 

*/

export default function Param(p) {

    // Le state comprend une copie du CET
    let c = {}
    Object.assign(c, p.p.cet)
    const [state, setState] = useState({
        cet: c,
        hasChanged: false,
        showConfirmationPopup: false,
        hasFetchedData: false,
    })

    if (!state.hasFetchedData) {
        //Fetching data from new API
        axios.post(p.api + "/api/n3", { proc: "params" }, { headers: { token: p.p.token } })
        .catch((error) => {
            if (error.response.status == 423) {
              p.logOut(error.response.data.message);
            }
          })
        .then(({ data, ...result }) => {
            console.log("data", data);
            const params = state.cet;
            params.taux_iccp = data[0].Taux_ICCP;
            params.taux_ifm = data[0].Taux_IFM;
            params.taux_primes = data[0].Taux_Primes;
            params.option_iccp = data[0].Taux_ICCP > 0;
            params.option_ifm = data[0].Taux_IFM > 0;
            params.option_primes = data[0].Taux_Primes > 0;
            setState({ ...state, cet: params, hasFetchedData: true });
        });
    }



    // saisie des valeurs par l'utilisateur
    const handelChange = (e) => {

        console.log('handelChange', e.target.id, e.target.value)
        let cet = state.cet
        //changed value in input field
        if (e.target.id.startsWith("taux_")) {
            let value = e.target.value.match(/\d{0,3}/)[0];
            let intValue = parseInt(value);

            if (isNaN(intValue)) {
                intValue = ""
            }
            else if(intValue<= 0) {
                intValue = 0;
                const targetType = e.target.id.split("_")[1];
                cet["option_"+targetType] = false;
            }
            else if (intValue > 100) {
                intValue = 100;
            }
            cet[e.target.id] = intValue;
        }
        //toggled switch
        else {
            let target = "";
            switch (e.target.id) {
                case "option_ifm":
                    target = "taux_ifm"
                    break;
                case "option_iccp":
                    target = "taux_iccp";
                    break;
                case "option_primes":
                    target = "taux_primes";
                    break;
            }

            console.log(e.target.value);
            if (e.target.value === "true") {
                cet[e.target.id] = false;
                cet[target] = 0;
            } else {
                cet[e.target.id] = true;
                cet[target] = 100;
            }
        }
        setState({ ...state, cet: cet, hasChanged: true })
    }

    const onBlur = (e) => {
        let cet = state.cet
        let value = e.target.value.match(/\d{0,3}/)[0];
        let intValue = parseInt(value);
        if(isNaN(intValue)) {
            intValue = 0;
            const targetType = e.target.id.split("_")[1];
            cet["option_"+targetType] = false;
            cet[e.target.id] = intValue;
        }
        setState({ ...state, cet: cet, hasChanged: true })
    }

    const valider = () => {
        let mod = {
            "proc": "modifcet",
            "modif": {
                "Option_IFM": state.cet.option_ifm,
                "Option_ICCP": state.cet.option_iccp,
                "Option_Primes": state.cet.option_primes,
                "Taux_IFM": state.cet.taux_ifm,
                "Taux_ICCP": state.cet.taux_iccp,
                "Taux_Primes": state.cet.taux_primes
            }
        };

        axios.post(p.api + "api/n3", mod, { headers: { token: p.p.token } })
        .catch((error) => {
            if (error.response.status == 423) {
              p.logOut(error.response.data.message);
            }
          })
        .then(() => {
            setState({ ...state, showConfirmationPopup: true, hasChanged: false });
        });
    }

    return (
        <>
            <div >


                <div className='Infos Params' style={{ marginTop: '25px' }}>


                    {/* ------------------------ I F M  */}
                    <div className='ParamCET'>

                        <FormControlLabel control={<StyledSwitch
                            id="option_ifm"
                            onChange={handelChange}
                            checked={state.cet.option_ifm}
                            value={state.cet.option_ifm}
                        />}
                            label="IFM"
                            sx={{ marginLeft: '2rem' }}
                        />

                        <TextField
                            style={{ width: 160, marginRight: '2rem' }}
                            id="taux_ifm"
                            name=''
                            label="% IFM"
                            value={state.cet.taux_ifm}
                            onChange={handelChange}
                            onBlur={onBlur}
                            InputLabelProps={{ shrink: true }}
                            size="small"
                            sx={{ marginLeft: 'auto' }}
                            disabled={!state.cet["option_ifm"]}
                        />
                    </div>



                    {/* ------------------------ I C C P  */}
                    <div className='ParamCET'>

                        <FormControlLabel control={<StyledSwitch
                            id="option_iccp"
                            onChange={handelChange}
                            checked={state.cet.option_iccp}
                            value={state.cet.option_iccp}
                        />} label="ICCP" sx={{ marginLeft: '2rem' }}
                        />

                        <TextField
                            style={{ width: 160, marginRight: '2rem' }}
                            id="taux_iccp"
                            name=''
                            label="% CP"
                            value={state.cet.taux_iccp}
                            onChange={handelChange}
                            onBlur={onBlur}
                            InputLabelProps={{ shrink: true }}
                            size="small"
                            sx={{ marginLeft: 'auto' }}
                            disabled={!state.cet["option_iccp"]}

                        />
                    </div>

                    <div className='ParamCET'>

                        <FormControlLabel control={<StyledSwitch
                            id="option_primes"
                            onChange={handelChange}
                            checked={state.cet.option_primes}
                            value={state.cet.option_primes}
                        />} label="Primes" sx={{ marginLeft: '2rem' }}
                        />

                        <TextField
                            style={{ width: 160, marginRight: '2rem' }}
                            id="taux_primes"
                            name=''
                            label="% Primes"
                            value={state.cet.taux_primes}
                            onChange={handelChange}
                            onBlur={onBlur}
                            InputLabelProps={{ shrink: true }}
                            size="small"
                            sx={{ marginLeft: 'auto' }}
                            disabled={!state.cet["option_primes"]}

                        />
                    </div>

                    <BlueAlert severity="info" sx={{ marginLeft: '1rem', marginRight: '1rem', marginTop: '1rem' }} iconMapping={{ info: <InfoIcon sx={{ color: '#1D91C3' }} /> }}>
                        <AlertTitle>
                            Si vous souhaitez fermer votre compte
                        </AlertTitle>
                        Veuillez vous rapprocher de votre agence.
                        <br />
                        Cependant, vous ne pourrez plus épargner à 12% d'intérêts.
                    </BlueAlert>
                    <StyledButton variant='contained' disabled={!state.hasChanged} onClick={valider} sx={{ alignSelf: 'center', marginTop: '1rem' }}>
                        Enregistrer
                    </StyledButton>
                </div>
            </div>

            <Popup open={state.showConfirmationPopup}
                // https://react-popup.elazizi.com/controlled-popup
                position="center"
                closeOnDocumentClick={true}
                onClose={e => { setState({ ...state, showConfirmationPopup: false }) }}
            >
                <div
                    style={{ textAlign: 'center', marginBottom: '10px' }}
                >
                    Vos modifications ont bien été enregistrées.
                </div>


                <StyledButton
                    onClick={e => { setState({ ...state, showConfirmationPopup: false }) }}
                    sx={{ marginBottom: '1rem', marginTop: '1rem' }}
                    variant="contained">
                    OK
                </StyledButton>

            </Popup>
        </>

    )

}
