// import * as React from 'react';
import { useState, useMemo, useEffect } from 'react'
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import StyledButton from './StyledButton';
import BlueAlert from './BlueAlert';
import { AlertTitle, Divider } from '@mui/material';
import {
  useNavigate,
  useLocation
} from "react-router-dom";
import { SvgIcon } from '@mui/material';
import StyledTableCell1 from './StyledTableCell1';
import StyledTableCell2 from './StyledTableCell2';
import { ReactComponent as BellOn } from '../icons/bell-on.svg';
import { ReactComponent as BlueChevron } from '../icons/blue-chevron.svg';
import PendingIBANChangeError from './PendingIBANChangeError';
import CircularProgress from '@mui/material/CircularProgress';
import TaxCertificateRequestPopup from './TaxCertificateRequestPopup';


/*
/*
Plusieurs méthodes de connexion : 
    1   le nom + mail ou tel permettent l'envoi d'une vérification + token OK
    2   login (mail) + mot de passe 
    3   oAuth2 avec un compte google ou Apple
*/


export default function Home({ name, api, matricule, poleEmploiData, hasPendingIBANChange, logOut,  ...p }) {
  const navigate = useNavigate();
  const location = useLocation();

  const [state, setState] = useState({
    agenceEnSaisie: 0,
    brut: false,
    detail: true,
    input: !!location.state && !!location.state.inputMode, // vrai si "en saisie"
    inputEnd: false, // vrai si on vient de terminer la saisie
    inputs: [], // Tab des montants saisis pour un déblocage par agence
    isTotalChecked: new Map(),
    totals: [], // Tab des totaux demandés
    montant: 0, // total des saisies
    totalDeblocage: 0,
    confirm: false, // true en confirmation
    iban2: false,    // true si on affiche Iban2
    ibanTransmis: false,
    ibanUpdated: false,
    hasFetchedData: false,
    soldes: [],
    showPoleEmploiNotification: false,
    displayPendingIBANChangeError: false,
  })

  const hasAlreadyRequestedTaxCertificate = () => {
    const lastRequest = localStorage.getItem("tax-certificate-requested-at");
    const currentYear = (new Date()).getFullYear();

    if(!!lastRequest && (new Date(lastRequest)).getFullYear() >= currentYear ) {
      return true;
    }

    return false;
  }

  const isTimeToShowTaxCertificatePopup = () => {
    //getMonth start with 0 for january...
    const month = (new Date()).getMonth();
    return  month >= 3 && month <= 5; 
  }
  useEffect(() => {
    setState({ ...state, showPoleEmploiNotification: (new Date()).getDate() >= 11 && !!poleEmploiData && poleEmploiData.length > 0 })
  }, [poleEmploiData])



  const montreSaisie = () => {
    if (hasPendingIBANChange) {
      setState({ ...state, displayPendingIBANChangeError: true })
    }
    else {
      p.setNavBar({ path: "/", title: "Demander un retrait" });
      setState({ ...state, input: !state.input, inputs: [], montant: 0, totalDeblocage: 0 })
    }

  }

  const cancelInput = () => {
    p.setNavBar({ path: "/", title: "Accueil" });
    setState({ ...state, inputEnd: false, input: false, totalDeblocage: 0, inputs: [],  isTotalChecked: new Map()});
  }

  const handelChange = (e) => {
    let m = e.target.value
    let point = false
    if (m[m.length - 1] == '.') { point = true }// En saisie : point à la fin ?

    m = parseFloat(m.replace(/[ |€]/, '').replace(",", ".").match(/\d{0,5}[.]\d{0,2}|\d{0,5}/)[0])
    let agence = e.target.name.substring(1) // Le champ s'apelle 
    let type = e.target.name[0] // T=totalité N=Montant
    let tab = state.inputs


    if (type == 'T') {
      // TOTALITE / agence
      let soldeAgence = state.soldes.find(elt => elt.RGPCNT_ID == agence)
      const isChecked = e.target.checked;
      if (isChecked) {
        // Si totalité, alors le montant = disponible + intérêts
        let mnt = soldeAgence.Solde - soldeAgence.Retrait + soldeAgence.Interet;
        tab[agence] = Number(mnt).toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' })
      }
      else {
        tab[agence] = Number(0).toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' })
      }
      const isTotalChecked = state.isTotalChecked;
      isTotalChecked.set(agence, isChecked);
      setState({ ...state, inputs: tab, agenceEnSaisie: agence, totalDeblocage: totalMontants(), inputEnd: false, isTotalChecked: isTotalChecked })
    }
    else {
      // Si le montant demandé dépasse alors on met le maxi
      let soldeAgence = state.soldes.find(elt => elt.RGPCNT_ID == agence)
      if (soldeAgence.Solde - soldeAgence.Retrait <= 0) {
        m = 0;
      }
      else if (soldeAgence.Solde - soldeAgence.Retrait <= m) {
        m = soldeAgence.Solde - soldeAgence.Retrait;
      }

      if (!isNaN(m)) {
        tab[agence] = Number(m);
        tab[agence] = point ? m + '.' : m
      }
      else {
        tab[agence] = Number(0);
      }

      const isTotalChecked = state.isTotalChecked;
      isTotalChecked.set(agence, false);
      setState({ ...state, inputs: tab, agenceEnSaisie: agence, inputEnd: false, isTotalChecked: isTotalChecked })
    }


  }


  const handelBlur = (e) => { // Pour gérer l'affichage en € à la sortie de la saisie
    let m = e.target.value.match(/\d{0,4}[\.]\d{0,2}|\d{0,4}/)
    let agence = e.target.name.substring(1)
    let tab = state.inputs
    // totalMontants
    if (m !== NaN) {
      tab[agence] = Number(m).toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' })
      setState({ ...state, inputs: tab, totalDeblocage: totalMontants() })
    }
  }

  const totalMontants = () => {
    let total = 0;
    for (var key in state.inputs) {
      if (typeof state.inputs[key] === 'string') {
        total += Number(state.inputs[key].replace('€', '').replace(',', '.').replace(String.fromCharCode(0x202F), '').replace(' ', ''))
      } else {
        total += Number(state.inputs[key])
      }
    }
    return total;
  }

  const valideDebloc = () => {
    // Envoi d'une demande de déblocage 
    let repartition = []
    for (var key in state.inputs) {
      if (key) {
        if (state.inputs[key]) {
          if (typeof state.inputs[key] === 'string') {
            repartition.push(
              {
                age: key.replace('N', ''),
                montant: Number(state.inputs[key].replace('€', '').replace(',', '.').replace(String.fromCharCode(0x202F), '').replace(' ', '')),
                total: state.isTotalChecked.has(`${key}`) && state.isTotalChecked.get(`${key}`)
              }
            )
          }
          if (typeof state.inputs[key] === 'number') {
            repartition.push(
              {
                age: key.replace('N', ''),
                montant: Number(state.inputs[key]),
                total: state.isTotalChecked.has(`${key}`) && state.isTotalChecked.get(`${key}`)
              }
            )
          }
        }
      }
    }

    let lastRequest = null;
    for (let agencyLine of repartition) {
      const solde = state.soldes.find(s => s.RGPCNT_ID == agencyLine.age);
      const amount = agencyLine.total ? (solde.Solde + solde.Interet - solde.Retrait) : agencyLine.montant;
      const interest = agencyLine.total ? solde.Interet : 0;
      if (amount > 0 || interest > 0) {
        const data = {
          "proc": "retrait",
          "retrait": {
            "rgpcnt_id": agencyLine.age,
            "netm": amount,
            "neti": interest,
            "totalite": agencyLine.total
          }
        };
        lastRequest = axios.post(api + "api/n3", data, { headers: { token: p.token } })
        // lastRequest = axios.post(testApi, data, { headers: { token: p.token } })
          .catch((error) => {
            if (error.response.status == 423) {
              logOut(error.response.data.message);
            }
          });
      }
    }

    if (lastRequest) {
      lastRequest.then(() => {
        setState({ ...state, detail: !state.detail, input: false, pop: true, hasFetchedData: false, inputs: [], isTotalChecked:  new Map()})
      });
    }
  }

  if (!state.hasFetchedData && !!p.token) {
    //Fetching data from new API
    axios.post(api + "api/n3", { proc: "display_solde" }, { headers: { token: p.token } })
      .catch((error) => {
        if (error.response.status == 423) {
          logOut(error.response.data.message);
        }
      })
      .then(({ data, ...result }) => {
        setState({ ...state, hasFetchedData: true, soldes: data });
      });
  }

  const totals = useMemo(() => {
    if (!state.soldes) {
      return { cet: 0, interet: 0, demande: 0, interet_paid: 0 };
    }
    return state.soldes.reduce((acc, cur) => {
      const newVal = { ...acc };
      newVal.cet += cur.Solde;
      newVal.demande += cur.Retrait;
      newVal.interet += (cur.Interet);
      newVal.interet_paid += cur.Int_paid_net;
      return newVal;
    }, { cet: 0, interet: 0, demande: 0, interet_paid: 0 });
  }, [state.soldes])

  /* Modif du 5 Aout : modifications demandées par les agences
   - Aération de la présentation tant que le détail n'est pas présent
  */
  //*********************************************************************************************************************** */


  const onCloseTaxCertificatePopup = () => {
    localStorage.setItem("tax-certificate-requested-at", (new Date()).toISOString());
    setState({ ...state, hasAlreadyRequestedTaxCertificate: true });
  }

  return (
    <>
      {
        p.token &&
        <>
          <div className='cet0'  >

            {
              state.input && state.soldes && state.soldes.length > 0 &&
              <>
                <BlueAlert severity="info" sx={{ marginLeft: '1rem', marginRight: '1rem', }} iconMapping={{ info: <InfoIcon sx={{ color: '#1D91C3' }} /> }}>
                  <AlertTitle>
                    Pour effectuer un retrait
                  </AlertTitle>
                  Veuillez indiquer dans la colonne de droite le montant du retrait souhaité par agence.
                </BlueAlert>
                <Typography sx={{ fontFamily: 'Open Sans', color: '#161616', fontSize: '0.875rem', fontWeight: 400, lineHeight: '1.25rem', textAlign: 'center', width: '100%', marginBottom: '1rem' }}>
                  IBAN renseigné : {p.iban}
                </Typography>

                <StyledButton onClick={() => navigate("/Iban2")} sx={{ alignSelf: "center" }} variant="outlined">Modifier mon IBAN</StyledButton>
              </>
            }
            {
              !state.input &&
              <>
                {
                  state.showPoleEmploiNotification &&
                  <div className="pe-notification" onClick={() => navigate('/PoleEmploi')}>
                    <div>
                      <h2><SvgIcon sx={{ fontSize: '1.125rem', paddingRight: '0.5rem', color: '#E11C31', verticalAlign: "baseline" }} component={BellOn} inheritViewBox />Actualisation Pôle Emploi</h2>
                      <p>Retrouvez les heures et salaires à déclarer !</p>
                    </div>
                    <div className="action-button">
                      <SvgIcon sx={{ width: '3rem', height: '3rem' }} component={BlueChevron} inheritViewBox />
                    </div>
                  </div>
                }

                <div className='cet1'>
                  <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', }}>
                      Montants en net estimé au {(new Date()).toLocaleDateString("fr-FR")}
                    </div>
                  </div>
                </div>

                <Card sx={{ borderRadius: '1rem', marginLeft: '1rem', marginRight: '1rem', width: 'calc(100% - 2rem)', marginBottom: '1.5rem' }}>
                  <CardContent className={"card-solde"}>

                    <Typography variant="h5" sx={{ fontFamily: 'Manrope', textAlign: 'left', fontSize: '1rem', fontWeight: 700, lineHeight: '1.5rem' }}>
                      Livret Leader n° {matricule}
                    </Typography>

                    <Typography sx={{ fontFamily: 'Open Sans', textAlign: 'left', fontSize: '0.875rem', fontWeight: 400, lineHeight: '1.25rem', textTransform: 'capitalize' }}>
                      {name.pre.toLocaleLowerCase()}&nbsp;{name.nom.toLocaleLowerCase()}
                    </Typography>

                    <Typography sx={{ fontFamily: 'Manrope', color: '#E11C31', fontSize: '1.5rem', fontWeight: 700, lineHeight: '2.125rem', textAlign: 'right' }}>
                      {Math.abs(Math.round((totals.cet + totals.interet - totals.demande))).toLocaleString('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                    </Typography>
                    {
                      totals.interet > 0 &&
                      <Typography sx={{ fontFamily: 'Open Sans', color: '#23956D', fontSize: '0.875rem', fontWeight: 600, lineHeight: '1.25rem', textAlign: 'right' }}>
                        Dont {Math.round(totals.interet - totals.interet_paid).toLocaleString('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 })} d'intérêts
                      </Typography>
                    }
                  </CardContent>

                </Card>
              </>
            }
          </div>


          {state.soldes && state.soldes.length > 0 &&
            <>
              <div className='detail'>
                <Divider />
                {state.soldes &&
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                      <TableHead color='red'>
                        <TableRow>
                          {/* 'maximum'<ContactSupportOutlinedIcon/> */}
                          <StyledTableCell1 align="left">Agence</StyledTableCell1>
                          <StyledTableCell1 align="center">Capital
                          </StyledTableCell1>
                          {!state.input &&
                            <>
                              <StyledTableCell1 align="right" sx={{ width: '100px' }}>Intérêts</StyledTableCell1>
                              {/* Colonne ajoutée pour montrer les totaux */}
                              <StyledTableCell1 align="right">Totalité</StyledTableCell1>
                            </>
                          }
                          {state.input &&
                            <>
                              <StyledTableCell1 align="right">Montant</StyledTableCell1>
                              <StyledTableCell1 align="right">Totalité</StyledTableCell1>
                            </>
                          }
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {state.soldes.map((row) => (
                          <TableRow
                            key={row.RGPCNT_ID + row.DATE_INT}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            height='10px'
                          >
                            {/* Colonne libellé agence */}
                            <StyledTableCell2 component="th" scope="row" align="left" sx={{ textAlign: "left" }}>
                              {row.RGPCNT_LIBELLE}
                            </StyledTableCell2>

                            {!state.input &&
                              <StyledTableCell2 align="right">
                                {
                                  (Math.abs(row.Solde - row.Retrait + row.Int_paid_net)).toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' })
                                }
                              </StyledTableCell2>
                            }
                            {state.input &&
                              <StyledTableCell2 align="right">
                                {
                                  // Colonne ajoutée pour montrer les totaux
                                  ((row.Solde - row.Retrait + row.Int_paid_net) < 0 ? 0 : Math.abs((row.Solde - row.Retrait + row.Int_paid_net))).toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' })
                                }
                              </StyledTableCell2>
                            }

                            {!state.input &&
                              <StyledTableCell2 align="right">
                                {
                                  Math.abs(row.Interet - row.Int_paid_net).toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' })
                                }
                              </StyledTableCell2>
                            }

                            {!state.input &&
                              <StyledTableCell2 align="right">
                                {
                                  (Math.abs(row.Solde - row.Retrait + row.Interet)).toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' })
                                }
                              </StyledTableCell2>
                            }

                            {state.input &&
                              <>

                                <TableCell align="right">
                                  <TextField
                                    sx={{ fontSize: '0.875rem' }}
                                    variant="outlined"
                                    name={'M' + row.RGPCNT_ID}
                                    value={state.agenceEnSaisie == row.RGPCNT_ID ? (state.inputs[row.RGPCNT_ID] ? state.inputs[row.RGPCNT_ID] : '') : (state.inputs[row.RGPCNT_ID] ? state.inputs[row.RGPCNT_ID].toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' }) : '')}
                                    onChange={handelChange}
                                    onBlur={handelBlur}
                                    disabled={state.isTotalChecked.has(`${row.RGPCNT_ID}`) && state.isTotalChecked.get(`${row.RGPCNT_ID}`)}
                                    inputProps={{
                                      min: 0, style: { textAlign: 'right', fontSize: '0.875rem' },
                                      underline: "none",
                                    }}
                                  />
                                </TableCell>
                                <StyledTableCell2
                                  align="right">
                                  {/* Vrai si totalité demandée pour l'agence */}
                                  <Checkbox
                                    checked={state.isTotalChecked.has(`${row.RGPCNT_ID}`) && state.isTotalChecked.get(`${row.RGPCNT_ID}`)}
                                    name={'T' + row.RGPCNT_ID}
                                    onClick={handelChange}
                                  />

                                </StyledTableCell2>
                              </>
                            }
                          </TableRow>
                        ))}

                        <TableRow // Ligne des totaux sous le tableau
                          key={'Totaux'}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          height='10px'
                        >
                          <StyledTableCell2 component="th" scope="row" className='large' align="left" sx={{ textAlign: "left" }}>
                            Totaux
                          </StyledTableCell2>
                          <StyledTableCell2 align="right">
                            {
                              Math.abs(totals.cet - totals.demande + totals.interet_paid).toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' })
                            }
                          </StyledTableCell2>

                          {
                            !state.input &&
                            <StyledTableCell2 align="right">
                              {
                                Math.abs(totals.interet - totals.interet_paid).toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' })
                              }
                            </StyledTableCell2>
                          }

                          {
                            state.input &&
                            <StyledTableCell2 align="right">{state.totalDeblocage.toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' })}</StyledTableCell2>
                          }
                          {
                            !state.input &&
                            // Colonne des totaux cet + intérêts
                            <StyledTableCell2 align="right">
                              {
                                Math.abs(totals.cet + totals.interet - totals.demande).toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' })
                              }
                            </StyledTableCell2>
                          }
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                }

              </div>
              <div style={{ width: '100%', height: state.input ? 120 : 96, left: 0, bottom: 0, position: 'sticky', backdropFilter: 'blur(4px)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {state.input &&
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <StyledButton
                      variant="contained"
                      onClick={valideDebloc}
                      style={{ marginBottom: '10px' }}
                      disabled={!(!state.iban2 && (state.totalDeblocage > 0) && !state.inputEnd)}
                    >
                      Retirer {state.totalDeblocage > 0 ? state.totalDeblocage.toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' }) : '0 €'}
                    </StyledButton>
                    <StyledButton
                      onClick={() => cancelInput()}
                      variant='outlined'
                    >
                      Annuler
                    </StyledButton>
                  </div>
                }
                {
                  !state.input &&
                  <StyledButton variant="contained" onClick={() => montreSaisie()}>
                    Demander un retrait
                  </StyledButton>
                }

              </div>
            </>
          }

          <Popup open={state.pop}
            // https://react-popup.elazizi.com/controlled-popup
            position="center"
            closeOnDocumentClick={true}
            onClose={e => { setState({ ...state, pop: false }) }}
          >
            <div
              style={{ textAlign: 'center', marginBottom: '10px' }}
            >
              La demande de <strong>retrait de {state.totalDeblocage > 0 ? state.totalDeblocage.toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' }) : '0 €'}</strong> a bien été prise en compte.
              <br /> Elle sera traitée dans les meilleurs délais.
            </div>

            <StyledButton
              onClick={e => { setState({ ...state, pop: false }) }}
              sx={{ marginBottom: '1rem', marginTop: '1rem' }}
              variant="contained">
              OK
            </StyledButton>

          </Popup>

          {
            state.displayPendingIBANChangeError &&
            <PendingIBANChangeError onClose={() => setState({ ...state, displayPendingIBANChangeError: false })} />
          }

          {
            !!state.soldes && !!state.soldes[0] && isTimeToShowTaxCertificatePopup() && !hasAlreadyRequestedTaxCertificate() &&
            <TaxCertificateRequestPopup api={api} logOut={logOut} token={p.token} perId={state.soldes[0].PER_ID} onClose={() => onCloseTaxCertificatePopup()} />
          }
        </>
      }

    </>
  )
}