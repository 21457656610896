import {
  useParams,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { useState, useEffect } from 'react'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import StyledButton from "./StyledButton";

export default function Logout({logOut, onCancel}) {


  let navigate = useNavigate();

  const bye = () => {
    logOut();
    onCancel();
  }



  return (
    <>
      <Popup open={true}
        // https://react-popup.elazizi.com/controlled-popup
        position="center"
        closeOnDocumentClick={false}
      >
        <div
          style={{ textAlign: 'center', marginBottom: '10px' }}
        >
          Voulez-vous vraiment <strong>vous déconnecter ?</strong>
        </div>


        <StyledButton
          onClick={e => { bye() }}
          sx={{marginBottom: '1rem', marginTop: '1rem'}}
          variant="contained">
          Déconnexion
        </StyledButton>

        <StyledButton
          onClick={onCancel}
          variant="outlined">
          Annuler
        </StyledButton>

      </Popup>
    </>
  )
}
