import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

export default styled(TableCell)(({ theme }) => ({
    fontSize: '0.75rem',
    fontWeight: 700,
    fontFamily: 'Open Sans',
    wordWrap: 'break-word',
    lineHeight: '1rem',
    color: '#161616',
    textAlign: 'left'
    // height: '10px',

}));