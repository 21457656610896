import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';

export default styled(Switch)(({ theme }) => ({
    '& .Mui-checked': {
        color: '#136CAC',
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: '#E8F1F7',
            borderColor: '#136CAC',
            borderStyle: 'solid',
            borderWidth: '1px'
    },
    color: '#FFFFFF',
    '& .MuiSwitch-switchBase + .MuiSwitch-track': {
        backgroundColor: '#545352',
        opacity: '1'
    },
    '& .MuiSwitch-thumb': {
        height: '22px',
    }
}));