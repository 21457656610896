import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

export default styled(TableCell)(({ theme }) => ({
    color: '#161616',
    fontSize: '0.875rem',
    fontWeight: 400,
    fontFamily: 'Open Sans',
    wordWrap: 'break-word',
    lineHeight: '1.25rem',
    textAlign: 'right'

}));