import { useState, useEffect } from 'react'
import axios from 'axios';
import { CircularProgress } from '@mui/material';

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import StyledButton from './StyledButton';


export default function TaxCertificateRequestPopup({ onClose, perId, token, logOut, api }) {

    const [state, setState] = useState({ isLoading: false, isDone: false });

    const closePopup = () => {
        if (!!state.isLoading) {
            console.log("isLoading return");
            return;
        }
        onClose();
    }

    const requestTaxCertificate = () => {
        setState({ ...state, isLoading: true });
        axios.post(api + "api/declaration", { "per_id": perId }, { headers: { token: token } })
            .catch((error) => {
                if (error.response.status == 423) {
                    logOut(error.response.data.message);
                }
            })
            .then((data, err) => {
                setState({ ...state, isLoading: false, isDone: true });
            });
    }

    return (
        <Popup open={true}
            position="center"
            closeOnDocumentClick={false}
            onClose={e => closePopup()}
        >
            <div
                style={{ textAlign: 'center', marginBottom: '10px' }}
            >
                {
                    !state.isLoading && !state.isDone &&
                    <p>Votre attestation fiscale {(new Date()).getFullYear() - 1} est disponible.<br/>
                        Voulez-vous la recevoir par e-mail?
                    </p>
                }
                {
                    !!state.isLoading && !state.isDone &&
                    <>
                        <CircularProgress color="primary" />
                        Veuillez patienter...
                    </>
                }
                {
                    !!state.isDone &&
                    <>
                        Vous allez recevoir, d'ici peu, votre attestation sur votre adresse e-mail.
                    </>
                }
            </div>

            {
                !state.isDone && !state.isLoading &&
                <>

                    <StyledButton
                        onClick={e => requestTaxCertificate()}
                        variant="contained">
                        Recevoir mon attestation
                    </StyledButton>
                    <StyledButton
                        onClick={e => closePopup()}
                        sx={{ marginBottom: '1rem', marginTop: '1rem' }}
                        variant="outlined">
                        Ne plus me demander
                    </StyledButton>


                </>
            }
            {
                state.isDone &&
                <StyledButton
                    onClick={e => closePopup()}
                    sx={{ marginBottom: '1rem', marginTop: '1rem' }}
                    variant="contained">
                    OK
                </StyledButton>
            }

        </Popup>
    )
}