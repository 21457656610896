/*
    Présentation de la liste des demandes
*/
import { useState, useEffect } from 'react'
import axios from 'axios';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import StyledTableCell1 from './StyledTableCell1';
import StyledTableCell2 from './StyledTableCell2';
import StyledButton from './StyledButton';

import { useNavigate } from 'react-router-dom';
import PendingIBANChangeError from './PendingIBANChangeError';


export default function Demandes(p) {

    const [state, setState] = useState({ pop: null, demandes: null, displayPendingIBANChangeError: false });
    const navigate = useNavigate();


    // Pour la présentation des éléments du tableau ==================================
    const transform = (d) => {
        const date = new Date(d) //.toISOString()
        return date.toLocaleDateString('fr-FR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        })
    }


    const lecture = (query) => {
        let q = JSON.parse(query)


        if (q.type === 'deblocage') {
            let total = 0
            let nbAge = 0

            // OLD : à l'époque de l'existence d'une répartition dans la demande. Depuis 1 dmande / +ieurs agence = autant de demandes que d'agences
            if (q.repartition !== undefined) {
                // console.log("Répartition")
                q.repartition.forEach(x => {
                    total += Number(x.montant)
                    if (Number(x.montant) > 0) { nbAge++ }
                })

                if (nbAge > 1) {
                    return (`Déblocage de ${total.toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' })} sur ${nbAge} agences`)
                } else {
                    return (`${total.toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' })}`)
                }
            }
        }

        if (q.age !== undefined && q.montant !== undefined) {
            if (q.totalité) {
                return (`${q.montant.toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' })} `)

            } else {
                return (`${q.montant.toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' })} `)
            }
        }

        if (q.type === 'retrait') {
            return (q.montant.toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' }))
        }
        if (['modif', 'parametre'].findIndex(el => el == q.type) > 0) {
            let texte = 'Modification : '
            texte = "Taux : "
            for (const [key, value] of Object.entries(q)) {

                if (key !== 'type') {
                    if (key === 'ifmRate' && value > 0) { texte += ' IFM=' + value + '% ' }
                    if (key === 'iccpRate' && value > 0) { texte += ' CP=' + value + '% ' }
                    if (key === 'bonusRate' && value > 0) { texte += ' Primes=' + value + '%' }
                }
            }
            if (texte === 'Taux : ') { texte = 'Suppression des taux' }

            return (texte)
        }

        if (q.type === 'iban') {
            return ('Nouvel IBAN' + ' xx' + q.iban.substr(-4))
        }
    }

    const getAgence = (row) => {
        return row.ageLib;
    }

    const navigateToWithdraw = () => {
        if (!p.p.hasPendingIBANChange) {
            navigate("/", { state: { inputMode: true } });
        }
        else {
            setState({ ...state, displayPendingIBANChangeError: true });
        }
    }

    //==================== L E G E N D E ===================================================
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    //================================================================================

    // Liste des demandes
    const getDemandes = () => {
        axios.post(p.api + "/api/demandes", { "message": "demandes" }, { headers: { token: p.p.token } })
        .catch((error) => {
            if (error.response.status == 423) {
              p.logOut(error.response.data.message);
            }
          })
            .then((data, err) => {
                if (err) { console.log('err', err) }
                if (data) {
                    setState({ ...state, demandes: data.data.LINES });
                }
            })
    }

    if (!state.demandes) {
        getDemandes()
    }


    return (
        <>
            <div className='Infos' style={{ height: 'auto' }}>

                {(!state.demandes || state.demandes.length == 0) ? <small>Pas de demande pour le moment</small> : ''}

                {state.demandes && state.demandes.length > 0 &&
                    <TableContainer component={Paper} sx={{ minWidth: 360, maxWidth: 500, marginTop: '40px', marginBottom: '1rem', marginLeft: 'auto', marginRight: 'auto', minHeight: 'calc(100% - 48px - 2rem)' }}>
                        <Table aria-label="simple table" sx={{ height: '100%' }}>
                            <TableHead>
                                <TableRow height='10px'>
                                    <StyledTableCell1 align="center" sx={{ textAlign: "center" }}>Agence</StyledTableCell1>
                                    <StyledTableCell1 align="center" sx={{ textAlign: "center" }}>Demande</StyledTableCell1>
                                    <StyledTableCell1 align="center" sx={{ textAlign: "center" }}>Date</StyledTableCell1>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {state.demandes.map((row) => (
                                    <TableRow
                                        key={row.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        height='10px'
                                    >
                                        <StyledTableCell2 sx={{ textAlign: "left" }}>
                                            {getAgence(row)}
                                        </StyledTableCell2>
                                        <StyledTableCell2 align="right">{lecture(row.query)}</StyledTableCell2>
                                        <StyledTableCell2 sx={{ textAlign: "left" }}>
                                            {transform(row.d1)}
                                        </StyledTableCell2>

                                    </TableRow>
                                ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
                <StyledButton sx={{ marginTop: "auto", marginBottom: "1rem" }} variant="contained" onClick={() => navigateToWithdraw()}>
                    Demander un retrait
                </StyledButton>
                {
                    state.displayPendingIBANChangeError && 
                    <PendingIBANChangeError onClose={() => setState({...state, displayPendingIBANChangeError: false})}/>
                }
            </div>

        </>
    )
}


