import BlueAlert from './BlueAlert';
import InfoIcon from '@mui/icons-material/Info';
import { useState, useEffect } from 'react';
import axios from 'axios';
import StyledTableCell1 from './StyledTableCell1';
import StyledTableCell2 from './StyledTableCell2';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { AlertTitle } from '@mui/material';


export default function PoleEmploi({ lines, ...props }) {


    const getMonthLabel = (value) => {
        let valueAsString = value + "";
        return `${valueAsString.substring(4)}/${valueAsString.substring(0, 4)}`;
    }

    return (
        <div>
            <BlueAlert severity="info" sx={{ marginLeft: '1rem', marginRight: '1rem', marginTop: '1rem' }} iconMapping={{ info: <InfoIcon sx={{ color: '#1D91C3' }} /> }}>
                <AlertTitle>
                    Que retrouvez-vous ici ?
                </AlertTitle>
                Un historique du nombre d'heures travaillées et du salaire sur les mois précédents.
            </BlueAlert>

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 360, maxWidth: 500, }} aria-label="simple table">
                    <TableHead>
                        <TableRow height='10px'>
                            <StyledTableCell1 align="left">Période</StyledTableCell1>
                            <StyledTableCell1 sx={{textAlign: "right"}} align="left">Heures</StyledTableCell1>
                            <StyledTableCell1 sx={{textAlign: "right"}} align="right">Salaire</StyledTableCell1>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {lines && lines.map((row) => (
                            <TableRow
                                key={row.PERIODE}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                height='10px'
                            >
                                <StyledTableCell2 sx={{ textAlign: "left" }}>
                                    {getMonthLabel(row.PERIODE)}
                                </StyledTableCell2>
                                <StyledTableCell2 align="right">{row.HRS.toLocaleString('fr-FR', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</StyledTableCell2>
                                <StyledTableCell2 sx={{ textAlign: "right" }}>
                                    {row.MTT.toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' })}
                                </StyledTableCell2>

                            </TableRow>
                        ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}