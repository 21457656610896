import styled from "styled-components";
import Alert from '@mui/material/Alert';

export default styled(Alert)(({ theme }) => ({
    backgroundColor: 'white',
    borderRadius: '0.25rem',
    borderColor: '#23956D',
    borderWidth: '1px',
    borderStyle: 'solid',
    color: '#545352',
    fontSize: '0.875rem',
    fontFamily: 'Open Sans',
    fontWeight: 400,
    marginBottom: '1rem',

    " .MuiTypography-root": {
        color: '#23956D',
    }

}));