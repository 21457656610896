/*

Présentation de l'historique des mouvements du CET

*/

import React, { useState, useEffect, useMemo } from 'react'

import axios from 'axios';

import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import { CircularProgress, ListItemText, Typography } from '@mui/material';
import groupBy from 'object.groupby';
import '../App.css';



/*
  - L'historique est reçu de p

*/

export default function Histo({ api, p, logOut }) {


  const [history, setHistory] = useState(null)

  const getHistory = () => axios.post(api + "api/n3", { "proc": "histo" }, { headers: { token: p.token } })
    .catch((error) => {
      if (error.response.status == 423) {
        logOut(error.response.data.message);
      }
    })
    .then((data, err) => {
      if (err) { console.log('err', err) }
      if (data) {
        data.data.sort((a, b) => sortHistory(a, b));
        setHistory(data.data)
      }
    });

  const sortHistory = (a, b) => {
    const dateA = new Date(a.D);
    const dateB = new Date(b.D);
    return dateA > dateB ? -1 : 1;
  }

  if (!history) {
    getHistory();
  }

  const groupByMonth = (histo) => {
    if (!histo) {
      return null;
    }
    const groupped = groupBy(histo, (row) => {
      const date = new Date(row.D);
      let formattedDate = date.toLocaleDateString("fr-FR", { year: 'numeric', month: 'long' });
      //To add capital to month name
      return formattedDate.charAt(0).toLocaleUpperCase() + formattedDate.slice(1);
    });
    return groupped;
  }

  const groupByDayAndAgency = (histo) => {
    console.log("histo", histo);
    const result = {};
    if (!histo) {
      return null;
    }
    for (let month of Object.keys(histo)) {
      result[month] = groupBy(histo[month], (row) => {
        const date = new Date(row.D);
        return date.toLocaleDateString("fr-FR", { year: 'numeric', month: '2-digit', day: '2-digit' }) + " - " + row.LIB;
      });
    }
    return result;
  }

  const getTransactionAmount = (row) => {
    return row.MTT === 0 ? row.ACQUIS_NET : row.MTT;
  }

  const getLabelDate = (date) => {
    const dateObj = new Date(date);
    return `${dateObj.getMonth() < 10 ? "0" + dateObj.getMonth() : dateObj.getMonth()}-${dateObj.getFullYear()}`;
  }

  const getTransactionLabel = (row) => {
    switch (row.type_ope) {
      case 1:
        return <>Retrait mensuel <br />{getLabelDate(row.D)}</>;
      case 2:
        return <>Retrait</>;
      case 3:
        return <>Clôture</>;
      case 4:
        return <>Demande <br />non traitée</>;
      case 5:
        return <>Intérêts <br />{getLabelDate(row.D)}</>;
      case 6:
        return <>Paiement en cours</>;
      case 8:
        return <>Placement période<br />{getLabelDate(row.D)}</>;
      case 9:
        return <>Avance sur Livret<br />{getLabelDate(row.D)} </>;
      case 10:
        return <>Reprise solde</>
      default: return <></>
    }
  }

  const histoByMonth = useMemo(() => groupByDayAndAgency(groupByMonth(history)), [history]);

  if (history === null) {
    return (
      <div id="loading-histo">
        <CircularProgress color="primary" />
      </div>
    )
  }
  else if (!histoByMonth) {
    return (
      <div className="histo-month" >
        <Typography className='month-label'>Aucun historique</Typography>
      </div>
    )
  }
  else {
    return (
      Object.keys(histoByMonth).map(month => (
        <React.Fragment key={month}>
          <div className="histo-month" >
            <Typography className='month-label'>{month}</Typography>
            {
              Object.keys(histoByMonth[month]).map(day =>
                <div className="histo-day" key={day}>
                  <Typography className='day-label'>{day}</Typography>
                  <List sx={{ marginRight: '1rem' }}>
                    {
                      histoByMonth[month][day].map((row, index) => {
                        const amount = getTransactionAmount(row);
                        return (
                          <ListItem className={index % 2 === 1 ? "light-row" : "dark-row"} >
                            {/* <span className='label'>Les paiements non encore exécutés</span> */}
                            <span className='label'>{getTransactionLabel(row)}</span>
                            <span className={`amount-${row.MTT <= 0 ? 'positive' : 'negative'}`}>
                              {(row.MTT <= 0 ? "+ " : "- ") + amount.toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' })}
                            </span>
                          </ListItem>
                        )
                      })
                    }
                  </List>
                </div>
              )
            }


          </div>
          <Divider />
        </React.Fragment>
      )
      ))
  }


}