import { Accordion, AccordionActions, AccordionDetails, AccordionSummary } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useState, useEffect, useMemo } from 'react';
import '../App.css';
import StyledButton from './StyledButton';
import { Call } from '@mui/icons-material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';


export default function Help() {
    const [expanded, setExpanded] = React.useState(null);

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <div id="aide">
            <h2>Vous avez une question ?</h2>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}
                >
                    <h3>1/ TUTO Comment se connecter ?</h3>
                </AccordionSummary>
                <AccordionDetails>
                    <p>
                        Pour vous connecter à l'application MyLeader, seulement deux informations sont nécessaires : < br />
                        <ul>
                            <li>Votre NOM de famille</li>
                            <li>Votre adresse mail <strong>transmise à votre agence</strong></li>
                        </ul>
                    </p>
                    <p>
                        <WarningAmberIcon className='warning-help-icon' />&nbsp;Vous recevrez un code par mail qu'il faudra renseigner pour valider votre identité et ouvrir votre espace Livret.
                    </p>
                    <iframe width="100%" height="315" src="https://www.youtube-nocookie.com/embed/kHzUmJ30b8o?si=z2EV2m6vElwjMXF6" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <h3>2/ TUTO Consulter Modifier Options Epargne</h3>
                </AccordionSummary>
                <AccordionDetails>
                    <p>
                        Il vous est possible de moduler vos choix d'épargne en termes de type d'épargne (IFM, ICCP ou Primes) et de taux.
                    </p>
                    <p>
                        <WarningAmberIcon className='warning-help-icon' />&nbsp;Il est très fortement conseillé de ne pas modifier les options d'épargne entre le 01 et le 11 du mois car les modifications risquent de ne pas être prises en compte.
                    </p>
                    <p>
                        Ci-dessous le guide pas à pas de la procédure :
                    </p>
                    <iframe width="100%" height="315" src="https://www.youtube-nocookie.com/embed/l8JjcmEHu3c?si=loh5VPFZDd_9Rm6p" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <h3>3/ TUTO Consulter Son Solde Details</h3>
                </AccordionSummary>
                <AccordionDetails>
                    <p>
                        Pour savoir où consulter son solde et retrouver l'historique des opérations sur votre livret, visualisez la vidéo ci-dessous :
                    </p>
                    <iframe width="100%" height="315" src="https://www.youtube-nocookie.com/embed/msNT3pUnoyQ?si=7WPflj2P_homsHhX" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <h3>4/ TUTO Déclarer Son Activité Pole Emploi</h3>
                </AccordionSummary>
                <AccordionDetails>
                    <p>
                        Afin de vous accompagner dans vos procédure d'actualisation/déclaration auprès de Pôle Emploi, nous mettons à votre disposition l'historique de vos rémunérations ainsi que les heures mensuelles effectuées.
                    </p>
                    <iframe width="100%" height="315" src="https://www.youtube-nocookie.com/embed/nTlJmcqumLI?si=9Fh5K6BHBk18h7_d" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <h3>5/ TUTO  Effectuer Retrait</h3>
                </AccordionSummary>
                <AccordionDetails>
                    <p>
                        Ci-dessous le guide vidéo pour effectuer un retrait
                    </p>
                    <iframe width="100%" height="315" src="https://www.youtube-nocookie.com/embed/aodzUbcoXcc?si=Vca0y-l6xw-poytq" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <h3>6/ TUTO Modifier Coordonnées Bancaires</h3>
                </AccordionSummary>
                <AccordionDetails>
                    <p>
                        Il est possible de transmettre via votre application MyLeader votre demande de modification de coordonnées bancaires.
                    </p>
                    <p>
                        <WarningAmberIcon className='warning-help-icon'/>&nbsp;  Les nouvelles coordonnées bancaires seront utilisées aussi pour le paiement de vos salaires.
                    </p>
                    <p>
                        Suite à votre demande effectuée sur MyLeader, il vous faudra :
                        <ol>
                            <li>Confirmer votre demande en cliquant sur le lien à recevoir par mail</li>
                            <li>Transmettre à votre agence le nouveau RIB</li>
                        </ol>

                        C'est seulement à l'issue de cela que vos coordonnées bancaires seront modifiées.
                    </p>
                    <p>Ci- dessous la vidéo expliquant toute la procédure :</p>
                    <iframe width="100%" height="315" src="https://www.youtube-nocookie.com/embed/ra2XtS8hRcE?si=iHoWCxdcPnwATSDl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panelfinal'} onChange={handleChange('panelfinal')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <h3>Que faire si je n'ai pas trouvé réponse à ma question ?</h3>
                </AccordionSummary>
                <AccordionDetails>
                    <p>
                        Si vous n'avez pas trouvé de réponse à votre question vous pouvez appeler notre numéro vert au <br /><a href="tel:0130106400">01 30 10 64 00</a>.
                    </p>
                </AccordionDetails>
                <AccordionActions>
                    <StyledButton variant='outlined' href='tel:0130106400'><Call />&nbsp;Appeler</StyledButton>
                </AccordionActions>
            </Accordion>
        </div>
    )
}